import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";
import { LoginComponent } from "./auth/login/login.component";

export const routes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("../app/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "auth",
    component: NbAuthComponent,
    children: [
      {
        path: "",
        component: NbLoginComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
    ],
  },

  {
    path: "",
    redirectTo: "auth/login", //'dashboard',
    pathMatch: "full",
  },
];

const config: ExtraOptions = {
  useHash: true,
  onSameUrlNavigation: "reload",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

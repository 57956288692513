import { ApiService } from "./../../../Restfull/api.service";
import { Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { interval, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "../../../global-service.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
const roles = JSON.parse(localStorage.getItem("Roles"));
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  visibleIcon = true;
  user: any;
  userLogin: any;
  countOrders = 0;
  countChanges = 0;
  countRequests = 0;
  
  languages = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "ar",
      name: "عربي",
    },
  ];
  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];
  currentLanguage = "en";
  currentTheme = "default";

  userMenu = [{ title: "Profile" }, { title: "Log out" }];
  myfunction() {
    this._router.navigate(["./pages/add-student-registeration"]);
  }
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private _router: Router,
    public translate: TranslateService,
    private apiSerivce: ApiService,
    public globalService:GlobalService
  ) {
    let user = localStorage.getItem("User");
    if (user) {
      if (user["userTypeId"] === 7) this.visibleIcon = false;
    }
    this.loadValues();
    translate.addLangs(this.languages.map((x) => x.code));
    let lang = localStorage.getItem("language");
    if (lang == null) {
      localStorage.setItem("language", "en");
      this.currentLanguage = "en";
      this.translate.use("en").toPromise<void>();
    } else {
      this.currentLanguage = lang;
      this.translate.use(lang).toPromise<void>();
    }
    // this._router.navigate(["./pages/dashboard"]);
  }
  async changeLanguage(event: any) {
    await this.translate.use(event).toPromise<void>();
    await localStorage.setItem("language", event);
    const prev = this._router.url;
    this._router.navigate(["/"]).then(async (data) => {
      await this._router.navigate([prev]);
    });
  }

  async ngOnInit() {
    interval(60000 * 5).subscribe((x) => {
      this.loadValues();
    });
    this.currentTheme = this.themeService.currentTheme;
    this.userLogin = JSON.parse(localStorage.getItem("User"));

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));





  }
  findRole(role:string){
  //  console.log(this.globalservice.findRole(role))
 return this.globalService.findRole(role);

  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  openOrders() {
    this._router.navigate(["./pages/orders"]);
  }
  openChangeTime() {
    this._router.navigate(["./pages/student-suggestion"]);
  }
  openRequests() {
    this._router.navigate(["./pages/request-user-booking-service"]);
  }
  async loadValues() {
   if( this.findRole('ORDERSOPERATION')==true)
   {
    this.apiSerivce
      .Get("invoice/getCountOrders", this.apiSerivce.token, null, false)
      .then((res: Response) => {
        this.countOrders = parseInt(res.toString());
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
    }
    if( this.findRole('STUDENTCOURSEOPERATIONS')==true)
    {this.apiSerivce
      .Get(
        "courseTimeline/getCountSuggestions",
        this.apiSerivce.token,
        null,
        false
      )
      .then((res: Response) => {
        this.countChanges = parseInt(res.toString());
      })
      .catch((e) => {
        console.log(e);
      });}
      if( this.findRole('BOOKINGOPERATION')==true){
    this.apiSerivce
      .Get(
        "bookingService/GetPendingRequest",
        this.apiSerivce.token,
        null,
        false
      )
      .then((res: Response) => {
        this.countRequests = parseInt(res.toString());
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
}

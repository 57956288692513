// import { Component } from '@angular/core';

// @Component({
//   selector: 'ngx-one-column-layout',
//   styleUrls: ['./one-column.layout.scss'],
//   template: `
//     <nb-layout windowMode>
//       <nb-layout-header fixed>
//         <ngx-header></ngx-header>
//       </nb-layout-header>

//       <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
//         <ng-content select="nb-menu"></ng-content>
//       </nb-sidebar>

//       <nb-layout-column>
//         <ng-content select="router-outlet"></ng-content>
//       </nb-layout-column>

//       <nb-layout-footer fixed>
//         <ngx-footer></ngx-footer>
//       </nb-layout-footer>
//     </nb-layout>
//   `,
// })
// export class OneColumnLayoutComponent {}
import { Component, OnInit } from "@angular/core";
import { NbLayoutDirection, NbLayoutDirectionService } from "@nebular/theme";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar [ngClass]="sidebar_class" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  constructor(private directionService: NbLayoutDirectionService) {
    localStorage.getItem("language")
      ? localStorage.getItem("language") === "ar"
        ? this.directionService.setDirection(NbLayoutDirection.RTL)
        : this.directionService.setDirection(NbLayoutDirection.LTR)
      : this.directionService.setDirection(NbLayoutDirection.LTR);
  }

  ngOnInit(): void {
    localStorage.getItem("language")
      ? localStorage.getItem("language") === "en"
        ? (this.sidebar_class = "myprojcet-menu-sidebar")
        : (this.sidebar_class = "myprojcet-menu-sidebar-rtl")
      : (this.sidebar_class = "myprojcet-menu-sidebar");
  }

  layout_direction: NbLayoutDirection = this.directionService.getDirection();
  sidebar_class: string = "myprojcet-menu-sidebar";
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  colors: any[] = [
    {
      primary: "#ff393b",
      secondary: "#ff393b",
      id: 1,
    },
    {
      primary: "#00ff80",
      secondary: "#00ff80",
      id: 2,
    },
    {
      primary: "#ecff1a",
      secondary: "#ecff1a",
      id: 3,
    },
    {
      primary: "#1ae3ff",
      secondary: "#1ae3ff",
      id: 4,
    },
    {
      primary: "#00f3ff",
      secondary: "#00f3ff",
      id: 5,
    },
    {
      primary: "#4BFF56",
      secondary: "#4BFF56",
      id: 6,
    },
    {
      primary: "#7FFFD4",
      secondary: "#7FFFD4",
      id: 7,
    },
    {
      primary: "#FFF069",
      secondary: "#FFF069",
      id: 8,
    },
    {
      primary: "#DDFDF9",
      secondary: "#DDFDF9",
      id: 9,
    },
    {
      primary: "#9DFF3B",
      secondary: "#9DFF3B",
      id: 10,
    },
    {
      primary: "#b5fe5b",
      secondary: "#b5fe5b",
      id: 11,
    },
    {
      primary: "#F5F5DC",
      secondary: "#F5F5DC",
      id: 12,
    },
    {
      primary: "#EDFF8C",
      secondary: "#EDFF8C",
      id: 13,
    },
    {
      primary: "#EDFF8C",
      secondary: "#EDFF8C",
      id: 14,
    },
  ];

  constructor() { }

  getColor(number) {
    console.log(number);
    if (number < 12) return this.colors.find((x) => x.id === number);
    else return this.colors.find((x) => x.id === number % 10);
  }

  getDyInFormat(date: Date) {
    let mayDate = new Date(date);
    return String(mayDate.getDate()).padStart(2, "0");
  }

  getMonthInFormat(date: Date) {
    let mayDate = new Date(date);
    return String(mayDate.getMonth()).padStart(2, "0"); //January is 0!
  }
  getYearInFormat(date: Date) {
    let mayDate = new Date(date);
    return mayDate.getFullYear();
  }

  returnValidIsoDate(date: Date) {
    let Updateddate = new Date(
      this.getYearInFormat(date),
      parseInt(this.getMonthInFormat(date)),
      parseInt(this.getDyInFormat(date)),
      0,
      0,
      0
    );
    return new Date(
      Updateddate.getTime() - Updateddate.getTimezoneOffset() * 60000
    ).toISOString();
  }

  roles = JSON.parse(localStorage.getItem("Roles"));
 findRole(title: string) {
  if (title.trim() !== "") {
    var pass = "NON";
    this.roles.forEach((element) => {
      if (element === title) {
        pass = "Passed";
      }
    });
    if (pass === "Passed") return true;
    else return false;
  }
}

}

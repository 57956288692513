import { ApiService } from "./../../Restfull/api.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserLogin } from "../authModel/loginModel";
import { NgForm } from "@angular/forms";

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  protected options: {};
  protected router: Router;
  redirectDelay: number;
  showMessages: boolean;
  strategy: string;
  errors: string[];
  messages: string[];
  userLogin: UserLogin;
  userEmail: string = null;
  userPassword: string;
  submitted: boolean;
  rememberMe: boolean;
  branches = []
  constructor(private apiSerivce: ApiService, private _router: Router) {
    let user = localStorage.getItem("User");

    if (user) {
      this.apiSerivce
        .Post(
          "identity/refresh",
          null,
          {
            token: localStorage.getItem("Token"),
            refreshToken: localStorage.getItem("RefreshToken"),
          },
          null
        )
        .then((res: Response) => {
          if (res["token"] && res["success"]) {
            localStorage.setItem("Token", res["token"]);
            localStorage.setItem("RefreshToken", res["refreshToken"]);
          }
        });
      this._router.navigate(["pages/dashboard"]);
    }
  }
  ngOnInit(): void {
    this.showMessages = false;
 
  }
  login(f: NgForm) {
    if (f.valid) {
      localStorage.removeItem("Roles");
      localStorage.removeItem("User");
      localStorage.removeItem("Token");
      localStorage.removeItem("RefreshToken");
      localStorage.removeItem("CurrentBranch");
      this.apiSerivce
        .Post(
          "identity/DashBoardLogin",
          null,
          {
            email_Mobile: f.value.email,
            password: f.value.password,
            language: 1,
          },
          true
        )
        .then((res: Response) => {
          //this.loadBranches(res["token"]);
          localStorage.setItem("Roles", JSON.stringify(res["role"]));
          localStorage.setItem("User", JSON.stringify(res["user"]));
          localStorage.setItem("Token", res["token"]);
          localStorage.setItem("RefreshToken", res["refreshToken"]);
          localStorage.setItem("CurrentBranch", JSON.stringify(res["user"]["branch"]));
          this.loadBranches();
          this._router.navigate(["/pages/dashboard"]);


        })
        .catch((er) => {
          this.apiSerivce.showToast("danger", "Message", er.toString());
        });
    } else {
      this.apiSerivce.showToast("danger", "Message", "Please enter valid data");
    }
  }

  async loadBranches() {
    await this.apiSerivce
      .Get("branch", this.apiSerivce.token, null, true)
      .then((res: Response) => {
        this.branches = res["data"].map((o) => {
          return {
            id: o.id,
            name: o.name,
            mobile: o.mobile,
            phone: o.phone
          };
        });
        localStorage.setItem("Branches", JSON.stringify(this.branches));
      });
  }
  getConfigValue(key: string): any { }

}

<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"
      >Music House<span>Dashboard</span></a
    >
  </div>
</div>

<div class="header-container">
  <nb-select
    [selected]="currentLanguage"
    (selectedChange)="changeLanguage($event)"
    status="primary"
  >
    <nb-option *ngFor="let lang of languages" [value]="lang.code">
      {{ lang.name }}</nb-option
    >
  </nb-select>
  <nb-actions size="medium" *ngIf="visibleIcon">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->

    <nb-action [hidden]="!findRole('STUDENTSOPERATIONS')"
      class="control-item"
      badgeDot
      badgePosition="top start"
      badgeStatus="danger"
      icon="person-outline"
      (click)="myfunction()"
    ></nb-action>
    <nb-action     [hidden]="!findRole('VIEWORDERS')"
      icon="code-download-outline"
      badgeText="{{ countOrders }}"
      badgeStatus="danger"
      style="font-size: 2px !important"
      (click)="openOrders()"
    >
    </nb-action>
    <nb-action   [hidden]="!findRole('VIEWBOOKINGS')"
      icon="flash-outline"
      badgeText="{{ countRequests }}"
      badgeStatus="danger"
      style="font-size: 2px !important"
      (click)="openRequests()"
    >
    </nb-action>
    <nb-action     [hidden]="!findRole('VIEWCHANGETIME')"
      icon="bell-outline"
      badgeText="{{ countChanges }}"
      badgeStatus="danger"
      style="font-size: 2px !important"
      (click)="openChangeTime()"
    >
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="userLogin.fullName"
        [picture]="userLogin.image"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

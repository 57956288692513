<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>

<nb-alert *ngIf="showMessages" status="danger">
  Oh snap!
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<form #f="ngForm" (ngSubmit)="login(f)" #form="ngForm">
  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input
      type="text"
      nbInput
      ngModel
      #email="ngModel"
      fullWidth
      name="email"
      autofocus
      status="basic"
      placeholder="User Email or Phone Number"
    />
  </div>
  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Password:</label>
      <!-- <a class="forgot-password caption-2" routerLink="../request-password"
        >Forgot Password?</a
      > -->
    </span>
    <input
      type="password"
      nbInput
      fullWidth
      autofocus
      ngModel
      #password="ngModel"
      name="password"
      status="basic"
      placeholder="Password"
    />
  </div>
  <!-- <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe">Remember me</nb-checkbox>
  </div> -->
  <button
    nbButton
    fullWidth
    status="primary"
    size="large"
    [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted"
  >
    Log In
  </button>
</form>

import { ErrorHandler, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ToasterConfig } from "angular2-toaster";
// import "style-loader!angular2-toaster/toaster.css";
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from "@nebular/theme";

@Injectable({
  providedIn: "root",
})

export class ApiService {
  token: any;

  //  cloud_url = "http://192.168.1.103:49550/api/v1.0/";      // megha
    cloud_url = "https://restapi.musichouse.ae/api/v1.0/";
      //  cloud_url = "http://192.168.1.205:2525/api/v1.0/";
  // cloud_url= "http://83.110.92.184:2525/api/v1.0/";
  // cloud_url= "http://192.168.10.252:6060/api/v1.0/";      // marco
   //cloud_url= "http://192.168.100.120:6060/api/v1.0/";      // marco Home
  constructor(public http: HttpClient, public toastrService: NbToastrService) {
    this.token = localStorage.getItem("Token");
  }
  async Get(Url, Token, Data, Message) {
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + localStorage.getItem("Token")
    );
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Methods", "GET");
    headers.append("Accept", "application/json");
    headers.append("content-type", "application/x-www-form-urlencoded");
    return await new Promise((resolve) => {
      this.http.get(this.cloud_url + Url, { headers }).subscribe(
        (res) => {
          //  console.log(Url, Token, Message, res);
          resolve(res);
        },
        async (err) => {

            this.showToast(
              "danger",
              "Error Message",
              JSON.stringify(err.error)
            );


        }
      );
    });
  } // GET :
  // async Get(Url, Token, Data, Message) {
  //   console.log(Token);
  //   if (Message === true) {
  //   }
  //   const headers = new HttpHeaders().set("Authorization", "Bearer " + Token);
  //   headers.append("Access-Control-Allow-Origin", "*");
  //   headers.append(
  //     "Access-Control-Allow-Methods",
  //     "GET,POST,OPTIONS,DELETE,PUT"
  //   );
  //   headers.append("Accept", "application/json");
  //   headers.append("content-type", "application/x-www-form-urlencoded");
  //   return await new Promise((resolve) => {
  //     this.http.get(this.cloud_url + Url, { headers }).subscribe(
  //       (res) => {
  //         console.log(Url, Token, Message, res);
  //         resolve(res);
  //       },
  //       async (err) => {
  //         this.showToast(
  //           "primary",
  //           "Message",
  //           "Oops !, something went wrong !" + Message
  //         );
  //         console.log("GET :", Message, err);
  //       }
  //     );
  //   });
  // }
  // End Get.
  // POST :
  async Post(Url, Token, Data, Message) {
    if (Message === true) {
    }
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + localStorage.getItem("Token")
    );
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Methods", "POST");
    headers.append("Accept", "application/json");
    headers.append("content-type", "application/x-www-form-urlencoded");
    return await new Promise((resolve) => {
      this.http.post(this.cloud_url + Url, Data, { headers }).subscribe(
        (res) => {
          resolve(res);
        },
        async (err) => {

          this.showToast("danger", "Error Message", JSON.stringify(err.error));
        }
      );
    });
  }

  async PostStudent(Url, Token, Data, Message) {
    if (Message === true) {
    }
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + localStorage.getItem("Token")
    );
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Methods", "POST");
    headers.append("Accept", "application/json");
    headers.append("content-type", "application/x-www-form-urlencoded");
    return await new Promise((resolve, reject) => {
      this.http.post(this.cloud_url + Url, Data, { headers }).subscribe(
        (res) => {
          resolve(res);
        },
        async (err) => {

          this.showToast("danger", "Error Message", JSON.stringify(err.error));

          reject();
          //this.showToast("danger", "Message", JSON.stringify(err.error));
          //console.log("POST", Message, err.error);

        }
      );
    });
  }

  // End Post.
  // Put :
  async PutWithReject(Url, Token, Data, Message) {
    if (Message === true) {
    }
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + localStorage.getItem("Token")
    );
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Methods", "PUT");
    headers.append("Accept", "application/json");
    headers.append("content-type", "application/x-www-form-urlencoded");
    return await new Promise((resolve, reject) => {
      this.http.put(this.cloud_url + Url, Data, { headers }).subscribe(
        (res) => {
          resolve(res);
        },
        async (err) => {

          this.showToast("danger", "Error Message", JSON.stringify(err.error));

          reject(err);
        }
      );
    });
  }

  async Put(Url, Token, Data, Message) {
    if (Message === true) {
    }
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + localStorage.getItem("Token")
    );
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Methods", "PUT");
    headers.append("Accept", "application/json");
    headers.append("content-type", "application/x-www-form-urlencoded");
    return await new Promise((resolve, reject) => {
      this.http.put(this.cloud_url + Url, Data, { headers }).subscribe(
        (res) => {
          resolve(res);
        },
        async (err) => {

          this.showToast("danger", "Error Message", JSON.stringify(err.error));
        }
      );
    });
  }
  // End Put.

  // Delete :
  async Delete(Url, Token, Data, Message) {
    if (Message === true) {
    }
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + localStorage.getItem("Token")
    );
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append(
      "Access-Control-Allow-Methods",
      "GET,POST,OPTIONS,DELETE,PUT"
    );
    headers.append("Accept", "application/json");
    headers.append("content-type", "application/x-www-form-urlencoded");

    return await new Promise((resolve) => {
      this.http
        .delete(this.cloud_url + Url, {
          headers,
          observe: "body",
          params: Data,
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          async (err) => {
            this.showToast("danger", "Error Message", JSON.stringify(err.error));
          }
        );
    });
  }
  // End Delete.

  positions: string[] = [
    NbGlobalPhysicalPosition.TOP_RIGHT,
    NbGlobalPhysicalPosition.TOP_LEFT,
    NbGlobalPhysicalPosition.BOTTOM_LEFT,
    NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    NbGlobalLogicalPosition.TOP_END,
    NbGlobalLogicalPosition.TOP_START,
    NbGlobalLogicalPosition.BOTTOM_END,
    NbGlobalLogicalPosition.BOTTOM_START,
  ];

  config: ToasterConfig;
  index = 1;
  destroyByClick = true;
  duration = 3000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = "primary";

  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `. ${title}` : "";

    this.index += 1;
    this.toastrService.show(body, ` ${this.index}${titleContent}`, config);
  }
}

export class NavigationData {
  static storage: any = null;
}
